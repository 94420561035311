@import '../tools/colors';

.o-body-1 {
  font-size: 16px;
  line-height: 20px;
}

.o-body-2 {
  font-size: 14px;
  line-height: 16px;
}

.o-body-2_space {
  line-height: 20px;
}

.o-body-3 {
  font-size: 12px;
  line-height: 16px;
}

.o-regular {
  font-weight: 400;
}

.o-semi-bold {
  font-weight: 600;
}

.o-bold {
  font-weight: 700;
}

.o-link {
  color: color('accent-green', 500);
  font-weight: 700;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.o-typographer {
  display: flex;
  flex-direction: column;

  & > * {
    margin: 0;
  }

  & > p,
  & > ul,
  & > ol {
    @extend .o-body-2;
  }

  & > * + p,
  & > * + ul,
  & > * + ol {
    margin-top: 8px;
  }

  & ul,
  & ol {
    list-style: none;
    padding: 0;
    & li + li {
      margin-top: 8px;
    }
    & li {
      position: relative;
      padding-left: 10px;
    }
  }

  & ol li::before,
  & ul li::before {
    position: absolute;
    right: 100%;
  }

  & ul li {
    &::before {
      content: '';
      display: block;
      height: 4px;
      width: 4px;
      border-radius: 9999px;
      background-color: color('accent-green', 500);
      top: calc(1em / 2 + 2px);
    }
  }

  & ol {
    counter-reset: ol-counter;

    & li {
      counter-increment: ol-counter;

      &::before {
        content: 'ol-counter';
      }
    }
  }

  & a {
    @extend .o-link;
  }
}
