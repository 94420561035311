@import '../../styles/tools/colors';

.root {
  display: flex;
  height: 36px;
  width: 36px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 9999px;
}

.image {
  width: 100%;
  height: auto;
}

.active {
  box-shadow: 0 0 0 3px color(accent-green, 500);
}

.tooltipContent {
  width: 320px;
  white-space: normal;
}

.tooltipName {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.tooltipDescription {
  margin-top: 12px;
  display: -webkit-box;
  -webkit-line-clamp: 9;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
