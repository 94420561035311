.root {
  display: flex;
}

.vertical {
  flex-direction: column;
}

.root > *:not(:first-child):not(:last-child) {
  border-radius: 0;
}

.horizontal > *:first-child {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.horizontal > *:last-child {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.vertical > *:first-child {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.vertical > *:last-child {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
