@import '../../styles/tools/colors';

.root {
  display: inline-grid;
  grid-auto-flow: column;
  align-items: center;
  column-gap: 8px;
  cursor: pointer;
  user-select: none;

  &.disabled {
    cursor: not-allowed;
  }
}

.controlContainer {
  position: relative;
}

.input {
  all: unset;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  appearance: none;
}

.control {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  background-color: color(basic, 100);
  border: 1px solid color(basic, 600);
  border-radius: 4px;

  .disabled & {
    background-color: color(basic, 400);
    opacity: 0.36;
  }
}

.icon {
  font-size: 8px;
  color: color(basic, 100);
}

.control:hover {
  border-color: color(accent-green, 500);
}

.input:checked + .control {
  background-color: color(accent-green, 600);
  border-color: transparent;
}

.input:checked + .control:hover {
  background-color: color(accent-green, 500);
}

.input:not(:checked) + .control .icon {
  opacity: 0;
}

.content {
  .disabled & {
    opacity: 0.36;
  }
}
