@import "../../styles/tools/media";

.root {
  display: flex;
  align-items: center;
}

.monogram {
  width: 37.7px;

  @include from(desktop) {
    width: 50.18px;
  }
}

.text {
  width: 59px;
  margin-left: 7.3px;

  @include from(desktop) {
    width: 77.82px;
    margin-left: 10px;
  }
}
