@mixin unwrapColorToken($name, $shadesMap) {
  @each $shadeName, $shadeValue in $shadesMap {
    --colors__#{$name}_#{$shadeName}: #{hexToRGB($shadeValue)};
  }
}

:root {
  /* #region COLORS */

  @each $colorName, $colorShadesMap in $colors {
    @include unwrapColorToken($colorName, $colorShadesMap);
  }

  /* #endregion COLORS */
}
