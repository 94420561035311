@import '../../styles/tools/colors';

.root {
  background-color: color(basic, 1100);
  border-color: color(basic, 1100);
  color: color(basic, 100);
  padding: 20px 24px;
  border-radius: 8px;
  transition-delay: 0.5s;

  &::before {
    display: none;
  }
}
