@import '../../styles/tools/media';

.root {
  display: grid;
  grid-template:
    'topLeft topCenter topRight'
    'centerLeft centerCenter centerRight'
    'bottomLeft bottomCenter bottomRight' / 1fr max-content max-content;
  gap: 8px;
}

.spacer {
  flex-grow: 1;
  flex-shrink: 1;
  pointer-events: none;
}

.controlArea {
  display: grid;
  gap: 8px;
  pointer-events: all;
}

.controlAreaHorizontal {
  grid-auto-flow: column;
}

.controlAreaVertical {
  grid-auto-flow: row;
}

.topLeft {
  grid-area: topLeft;

  @include from(desktop) {
    margin-left: 4px;
  }
}

.topCenter {
  grid-area: topCenter;
}

.topRight {
  grid-area: topRight;
}

.centerLeft {
  grid-area: centerLeft;
}

.centerCenter {
  grid-area: centerCenter;
}

.centerRight {
  grid-area: centerRight;
}

.topLeft,
.topCenter,
.topRight {
  align-self: start;
}

.centerLeft,
.centerRight,
.centerCenter {
  align-self: center;
}