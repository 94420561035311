@import '../../styles/tools/media';
@import '../../styles/tools/colors';

.root {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9000;

  display: grid;
  grid-template: 'window' auto 
                 'controls' 1fr / 100%;
  gap: 8px;
  box-sizing: border-box;
  padding: 8px;
  pointer-events: none;

  @include from(desktop) {
    padding: 12px;
    grid-template: 'panelMain controls' 100% / 460px 1fr;
    gap: 8px;
  }
}

.panelSlot {
  max-height: 100%;

  & > * {
    pointer-events: all;
  }
}

.mainPanelSlot {
  grid-area: window;

  @include from(desktop) {
    grid-area: panelMain;
  }

  z-index: 1;
}

.controls {
  grid-area: controls;
}

.tooltip {
  position: relative;

  &:hover {
    &::before {
      display: block;
    }
  }

  &::before {
    position: absolute;
    display: none;
    top: -8px;
    left: -332px;
    content: attr(data-tooltip);
    background-color: color(basic, 1100);
    border-color: color(basic, 1100);
    color: color(basic, 100);
    padding: 20px 24px;
    border-radius: 8px;
    transition-delay: 0.5s;
  }
}
