body {
  font-family: 'Inter', sans-serif;
  color: color(basic, 1300)
}

*,
*::after,
*::before {
  box-sizing: border-box;
}
