.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.form {
  margin-top: 24px;
}

.button {
  margin-top: 16px;
}

.text {
  margin: 0;
}

.checkbox {
  margin-top: 24px;
}
