@import '../../styles/tools/colors';

.root {
  display: flex;
  flex-direction: column;
}

.label {
  color: color(basic, 900);
  margin-bottom: 6px;

  @at-root .required & {
    &::after {
      content: ' *';
      color: color(error, 700);
    }
  }
}

.errorMessage {
  color: color(error, 700);
  margin-bottom: 6px;
}

.controlWrapper {
  display: flex;
  align-items: center;
  border: 1px solid color(basic, 600);
  background-color: color(basic, 100);
  border-radius: 6px;
  padding: 15px 19px;
  box-sizing: border-box;

  &:focus-within {
    border-color: color(accent-green, 600);
    box-shadow: 0 0 0 4px color(accent-green, 600, .4);
  }

  &:hover {
    border-color: color(basic, 900);
    cursor: text;
  }

  @at-root .disabled & {
    border-color: transparent;
    background-color: color(basic, 300);
    cursor: not-allowed;

    & .control {
      pointer-events: none;
    }
  }

  @at-root .invalid & {
    border-color: color(error, 700);
  }
}

.control {
  display: block;
  min-width: 0;
  width: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  border: none;
  padding: 0;
  background-color: transparent;
  font-family: 'Inter', sans-serif;

  &:focus,
  &:active,
  &:focus:global(.focus-visible) {
    outline: none;
  }
}
