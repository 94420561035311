@import '../../styles/tools/colors';

.root {
  background-color: color(basic, 1100);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9010;
  overflow: auto;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  overflow: auto;
}