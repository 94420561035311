@import '../../styles/tools/colors';

.root {
  display: flex;
  position: relative;
}

.badge {
  position: absolute;
  top: -6px;
  left: 38px;
  height: 12px;
  min-width: 12px;
  border: none;
  border-radius: 9999px;
  background-color: color(error, 600);

  &:hover {
    cursor: pointer;
    background-color: color(error, 500);
  }
}
