@import
  '../../../styles/tools/media',
  '../../../styles/tools/colors';

.root {
  display: flex;
  text-decoration: none;
  color: color(basic, 900);
}

.root:hover {
  color: color(basic, 1300);
}

.icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  margin-top: -2px;
}

.withIcon {
  margin-left: 12px;

  @include from(desktop) {
    margin-left: 16px;
  }
}

.withoutIcon {
  margin-left: 32px;

  @include from(desktop) {
    margin-left: 36px;
  }
}