@import '../../styles/tools/colors';

.root {
  display: grid;
  grid-auto-flow: column;
  border-radius: 6px;
  cursor: pointer;
  font: inherit;
  border: 0;
}

.root:disabled {
  cursor: not-allowed;
}

.medium {
  padding: 6px 12px;
  column-gap: 6px;
}

.medium.onlyIcon {
  padding: 6px;
}

.large {
  padding: 12px 24px;
  column-gap: 8px;
}

.large.onlyIcon {
  padding: 12px;
}

.blue {
  background-color: color(basic, 1000);
  color: color(basic, 100);
  --focus-color: #{color(basic, 900, 0.6)};
}

.blue:hover {
  background-color: color(basic, 900);
}

.blue:active {
  background-color: color(basic, 1100);
}

.blue:disabled {
  background-color: color(basic, 1000, 0.36);
}

.green {
  background-color: color(accent-green, 600);
  color: color(basic, 100);
  --focus-color: #{color(accent-green, 500, 0.6)};
}

.green:hover {
  background-color: color(accent-green, 500);
}

.green:active {
  background-color: color(accent-green, 700);
}

.green:disabled {
  background-color: color(accent-green, 600, 0.36);
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  font-size: 20px;
  line-height: 100%;
}
