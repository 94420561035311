@import
  '../../styles/tools/media',
  '../../styles/tools/colors';

.root {
  position: fixed;
  top: 0;
  right: 0;
  display: grid;
  row-gap: 1px;
  align-content: start;
  width: 100%;
  height: 100%;
  background-color: color(basic, 300);
  z-index: 9001;
  transform: translateX(100%);
  transition: transform 200ms ease-out;

  @include from(desktop) {
    width: 300px;
    box-shadow: 0 0 12px rgba(#000, 0.12);
  }
}

.enterActive,
.enterDone {
  transform: translateX(0);
}

.content {
  display: none;
}

.enterActive,
.enterDone,
.exitActive {
  .content {
    display: contents;
  }
}

.closeBtn {
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: transparent;
  height: 36px;
  width: 36px;
  font-size: 16px;
  color: color(basic, 700);
  cursor: pointer;
}

.header {
  display: flex;
  justify-content: space-between;
  padding: 24px 20px;
  background-color: rgb(var(--colors__basic_1100));

  @include from(desktop) {
    padding: 28px 24px;
  }
}

.nav {
  display: contents;
}
