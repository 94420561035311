@import
  '../../../styles/tools/media',
  '../../../styles/tools/colors';

.root {
  display: grid;
  grid-auto-flow: column;
  justify-content: start;
  column-gap: 12px;
  padding: 24px 20px;
  background-color: color(basic, 100);

  @include from(desktop) {
    padding: 28px 24px;
  }
}

.links {
  display: grid;
  row-gap: 12px;
}
