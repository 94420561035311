@import '../../styles/tools/colors',
        '../../styles/tools/media';

.root {
  padding: 24px 20px;
  border-radius: 12px;
  background: color(basic, 100);
  width: 280px;

  @include from(tablet) {
    padding: 40px;
    width: 600px;
  }

  position: relative;
}

.title {
  display: block;
  margin-top: 28px;

  @include from(tablet) {
    margin-top: 12px;
  }
}

.content {
  margin-top: 12px;
}

.closeBtn {
  display: flex;
  height: 44px;
  width: 44px;
  position: absolute;
  top: 8px;
  right: 8px;
  background: transparent;
  border: none;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  color: color(basic, 800);
  cursor: pointer;
}