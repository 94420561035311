.panel-left-enter {
  opacity: 0.01;
  transform: translateX(-100%);
}

.panel-left-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition:
    transform 250ms cubic-bezier(0.4, 0.0, 0.2, 1),
    opacity 200ms cubic-bezier(0.4, 0.0, 0.2, 1);
}

.panel-left-exit {
  opacity: 1;
  transform: translateX(0);
}

.panel-left-exit-active {
  opacity: 0.01;
  transform: translateX(-100%);
  transition:
    transform 250ms cubic-bezier(0.4, 0.0, 0.2, 1),
    opacity 200ms cubic-bezier(0.4, 0.0, 0.2, 1);
}
