@import './styles/tools/media';

.root {
  width: 100%;
  height: 100%;
  position: relative;
}

.map {
  width: 100%;
  height: 100%;
}

.overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 9000;
  pointer-events: none;

  display: grid;
  grid-template: 'window' auto 
                 'controls' 1fr / 1fr;
  box-sizing: border-box;
  padding: 8px;

  @include from(desktop) {
    padding: 12px;
    grid-template: 'panelMain panelSecondary controls' 100% / 460px 280px auto;
    gap: 8px;
  }
}

.panelSlot {
  max-height: 100%;

  & > * {
    pointer-events: all;
  }
}

.mainPanelSlot {
  grid-area: window;

  @include from(desktop) {
    grid-area: panelMain;
  }

  z-index: 1;
}

.secondaryPanelSlot {
  grid-area: window;

  @include from(desktop) {
    grid-area: panelSecondary;
  }
  
  z-index: 2;
}

.controls {
  grid-area: controls;
}

.withGlobalAnnouncement {
  height: calc(100% - 50px);
}
